import React from "react";
import { Container, Row, Col } from "react-bootstrap";
// import PlayVideo from "../Play/PlayVideo";
import gql from "graphql-tag"
import { useQuery } from '@apollo/client';

import CTABtnModule from "gatsby-theme-starberry-lomondgroup/src/modules/cta_btn_module";
import ContentModule from "gatsby-theme-starberry-lomondgroup/src/modules/content_module";
import ImageModule from "gatsby-theme-starberry-lomondgroup/src/modules/image_module";


import "gatsby-theme-starberry-lomondgroup/src/components/LocalExperience/LocalExperience.scss";

const LocalExperience = ({Module, GQLPage, office_template}) => {

const LocalExperienceQuery = gql(`
    query LocalExperienceQueryCustom($id: ID!) {
      page(id:$id) {
        add_components{
            ... on ComponentComponentsLocalExperience {
                id
                title
                content
                background_color: bg_color
                image_align
                button_cta {
                  theme
                  target
                  custom_link
                  id
                  label
                  menu_item {
                    slug
                    parent {
                      slug
                      parent {
                        slug
                        parent {
                          slug
                        }
                      }
                    }
                  }
                }
                image {
                  url
                  alternativeText
                }
            }
        }
      }
    }
 `)

  const { data, loading, error } = useQuery(LocalExperienceQuery, {
    variables: {
        id: GQLPage.id
    }
  });


  var GQlModule = data?.page?.add_components?.length > 0 && data?.page?.add_components.filter(item => item.id === Module.id)

  //console.log("GQlModule ==>", GQlModule);

  Module = GQlModule.length > 0 ? GQlModule[0] : [];

    var imagename = office_template ? "office.video_section_image.video_image" : "pages.video_section_image.video_image";

    let processedImages = JSON.stringify({});
    if (GQLPage?.imagetransforms?.video_section_image_Transforms) {
        processedImages = GQLPage?.imagetransforms?.video_section_image_Transforms;
    }

    return (
        <React.Fragment>
            <section className={`local-experience ${Module?.title ? Module?.title.toLowerCase().replace(/ /g, '_') : ""} ${Module?.background_color} ${Module.image_align}`}>
                <Container>
                    <Row>
                        <Col>
                            <div className="local-experience-wrapper">    
                                <div className={`video-img img-zoom`}>
                                    {Module?.image?.url && (
                                        GQLPage.page_class === "landlord_service" ? 
                                          <ImageModule ImageSrc={Module.image} altText={Module?.title} />
                                        :                                        
                                          <ImageModule ImageSrc={Module.image} altText={Module?.title} imagetransforms={processedImages} renderer="srcSet" imagename={imagename} strapi_id={GQLPage?.id} /> 
                                      )
                                    }
                                </div>

                                <div className="video-content">
                                    <h3>{Module?.title}</h3>
                                    
                                    {Module?.content && <ContentModule Content={Module?.content} /> }

                                    {Module?.button_cta && <CTABtnModule button_cta={Module.button_cta} /> }
                                </div>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </section>
        </React.Fragment>
    );
};
export default LocalExperience;
