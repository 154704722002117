import React, { useState, useEffect } from "react";
import { Container, Row, Col } from "react-bootstrap";
import Select from 'react-select';
import ReactPaginate from 'react-paginate';
import _ from "lodash";
import "gatsby-theme-starberry-lomondgroup/src/components/OurTeam/OurTeam.scss"
import TeamCard from "gatsby-theme-starberry-lomondgroup/src/components/OurTeam/TeamCard"
import {OfficeList} from "gatsby-theme-starberry-lomondgroup/src/common/queries/common_use_query";

const OurTeam = ({ teams, categories, teams_count }) => {

    const{loading, error, data:branch_list} = OfficeList();
    const[category, setCategory] = useState("");
    const[branch, setBranch] = useState("")

    let [teamList, setTeamList] = useState([]);
    let [teamListPagination, setTeamListPagination] = useState([]);

    const [pageCount, setPageCount] = useState(0);
    const itemsPerPage = 20;
    const [itemOffset, setItemOffset] = useState(0);

    //console.log("pageCount", pageCount)

    var options = [
        { label: 'All Categories', value: '' }
    ]

    var branch_options = [
        { label: 'All Branches', value: '' }
    ]

    // const applyCategory = (filt, type) => {

    //     var teams_list = teams;
    //     var team_filter = [];
    //     if (type === "category") {
    //         teams_list = teams.length > 0 && teams.filter((item => item.category === filt))
    //     }

    //     setTeamList(teams_list);
    // }

    useEffect(() => {
        var teams_list = teams;
        var team_filter = [];
        
        if (category) {
            teams_list = teams_list.length > 0 && teams_list.filter((item => item.category === category))
        }

        if (branch) {
            teams_list.length > 0 && teams_list.map((item) => {
                if(item.offices.length > 0 && item.offices.some(item2 => item2.id === branch)){
                    team_filter.push(item)
                }
            })

            teams_list = team_filter
        }

        setItemOffset(0);

        setTeamList(teams_list);
        setTeamListPagination(teams_list)

    },[teams, category, branch])

    useEffect(() => {
        const endOffset = itemOffset + itemsPerPage;
        setTeamListPagination(teamList.slice(itemOffset, endOffset));
        setPageCount(Math.ceil(teamList.length / itemsPerPage));

        setTimeout(() => {
            window.scrollTo(0, 500);
        }, 500)
        
    }, [itemOffset, teamList]);

    const handlePageClick = (event) => {
        //console.log("blog_test", event)

        const newOffset = (event.selected * itemsPerPage) % teamList.length;

        //console.log("newOffset", newOffset)
        setItemOffset(newOffset);
    };


    //console.log("categories", categories);

    _.map(categories, (data) => {
        if(data){
            var cat_name = data.replace('General', 'Head Office')
            options.push({ label: cat_name.replace(/_/g, ' '), value: data })
        }
        
    })

    branch_list?.offices?.length > 0 && branch_list?.offices.map((item) => {
        if(item){
            branch_options.push({ label: item.name, value: item.id })
        }
    })

    //console.log("teams_count", teams_count);

    return (
        <React.Fragment>
            <section className="our-team location-block">
                <Container>
                    <Row>
                        <Col>
                            <div className="filter-wrapper">
                                <span className="sm-text">Showing {teamList.length} {(teamList.length > 1) ? 'Staff members' : 'Staff member'}</span>

                                    <div className="filter-boxes">
                                        <strong className="filter-box">Filter by:</strong>

                                        {process.env.GATSBY_SITE_NAME === "Thornley Groves" && 
                                            branch_options.length > 0 && 
                                            <Select
                                                options={branch_options}

                                                placeholder="All Branches"
                                                onChange={(e) => {
                                                    //console.log('teamxxs', e)
                                                    setBranch(e.value, "branch")
                                                }}
                                                className="react-select filter-select me-0 me-md-3"
                                                classNamePrefix="react-select" 
                                                //menuIsOpen={true}
                                                
                                            />
                                        }
                                        
                                        {categories.length > 0 &&
                                            <Select
                                                options={options}

                                                placeholder="All Categories"
                                                onChange={(e) => {
                                                    //console.log('teamxxs', e)
                                                    setCategory(e.value)
                                                }}
                                                className="react-select filter-select"
                                                classNamePrefix="react-select" 
                                                //menuIsOpen={true}
                                                
                                            />
                                        }
                                    </div>
                            </div>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <div className="team-details">
                                {teamListPagination?.length > 0 ?
                                    teamListPagination?.map((team, index) => (
                                        <TeamCard key={index} TeamList={team} />
                                    ))
                                :
                                    (category || branch) ?
                                    <div className="col-12 text-center">No teams found.</div>
                                    :   ""                                
                                }
                            </div>

                            {pageCount > 1 &&
                                <div className="pagination-section">
                                    <ReactPaginate
                                        breakLabel="..."
                                        nextLabel="next >"
                                        onPageChange={handlePageClick}
                                        pageRangeDisplayed={5}
                                        pageCount={pageCount}
                                        previousLabel="< previous"
                                        renderOnZeroPageCount={null}
                                        className="pagination"
                                        pageClassName="page-item"
                                        pageLinkClassName="page-link"
                                        previousClassName="prev page-item"
                                        previousLinkClassName="page-link"
                                        nextClassName="next page-item"
                                        nextLinkClassName="page-link"
                                        activeClassName="active"
                                    />
                                </div>
                            }
                        </Col>
                    </Row>
                </Container>
            </section>
        </React.Fragment>
    );
};
export default OurTeam;
